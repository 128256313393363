import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/aida.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/samuel-boucher.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image

import Footer from "../components/footer"

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto29.png" // Tell webpack this JS file uses this image
import foto16 from "../images/fotoscursos/foto16.jpg" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Curso Avançado<br></br> de Necropsias e Urgências</span> <br></br>
              em Coelhos
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal13} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Dr. Samuel Boucher
                      </div>
                    
                    <div className="titulo inter-light">
                    Consultor Especialista em Cunicultura
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        10 e 11 de Janeiro de 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      400€ +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/coelhos-1.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                {/* <div className="form">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSepcRnBA2atHSATWzTDx9m0-ibOMzQk1LS2AhxLrC8xJrnNxw/viewform" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div> */}
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Formação intensiva em necropsia para técnicos interessados em compreender um novo nível de compreensão dos processos patológicos. Duarante esta formação será feita uma revisão e um update nas doenças mais e menos comuns na cunicultura e a correta abordagem em termos de diagnóstico.
                </div>
                <div className="logos">
                  
             

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
   
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 10/01/2023
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:00 - 11:15</span> | <span class="mod">Módulo 1</span> - <span class="txt">Doenças comuns em cunicultura - Diagnóstico, tratamento e profilaxia</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">11:15 - 11:45</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:45 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Doenças pouco comuns</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 15:45</span> | <span className="mod">Módulo 3</span> - <span class="txt">Sessão de necropsia I</span></p>
                                </div>
   
                                <div class="modulo">
                                  <p><span className="date">15:45 - 16:00</span> | <span className="mod">A abordagem correta a urgências</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 17:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Sesão de necropsia I</span></p>
                                </div>
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 10/01/2023
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">09:00 - 11:15</span> | <span class="mod">Módulo 1</span> - <span class="txt">Sessão de Necropsia II</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">11:15 - 11:45</span> | <span className="mod">Coffee-Break</span> </p>
                                </div>
                                <div class="modulo">
                                <p><span className="date">11:45 - 13:00</span> | <span class="mod">Módulo 2</span> - <span class="txt">Sessão de Necropsia III</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 17:30</span> | <span className="mod">Módulo 2</span> - <span class="txt">Visita técnica</span></p>
                                </div>
        
          
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                                
                          
                             


                    


             
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/coelhos-1.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                {/* <div className="form">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSepcRnBA2atHSATWzTDx9m0-ibOMzQk1LS2AhxLrC8xJrnNxw/viewform" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 

                </a>
          </div> */}
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
      

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal13} alt="" />

              </div>
              <div className="nome">
              Dr. Samuel Boucher
                            </div>
              <div className="titulo">
              Consultor Especialista em Cunicultura
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Médico Veterinario especialista em Cunicultura - Labovet Conseil - Grupo Reseau Cristal - França 
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Autor do livro: "Maladies du Lapin" (Doenças do coelho)
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Co-autor da descuberta do virus da doença hemorrágica viral (RHDV2)</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Membro fundador da "Comisión de Conejos de SNGTV" e juiz de varias raças
</p>  
<p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Vicepresidente da "Federación Francesa de Cunicultura" e participa Acrdo Europeu para a Cunicultura
</p>
<p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Escreveu ou foi co-autor de mais de 20 livros e artigos científicos em cunicultura</p>
                 
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto16} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
